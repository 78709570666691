import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #e5ddd5;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  .integration-card {
    background: white;
    position: relative;
    width: 100%;
    border-radius: 10px;    
    box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
    margin: auto;
    text-align: center;
    padding: 25px;
    padding-bottom: 30px;
    cursor: pointer;
    min-height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .integration-card:hover{  
    box-shadow: 2px 2px 6px rgba(0,0,0,0.4);
  }
  a {
    text-decoration: none;
    color: black;
  }
  img {
    margin-bottom: 10px;
    height: 70px;
  }
  @media screen and (max-width: 768px) {
    img {
      height: 40px;
    }
  }
`;
