import request from "../../utils/request";

export const getProductsJson = async shopId => {
  return request(`data/shop/${shopId}/products.json`, {
    method: "GET"
  });
};

export const getShopSetting = async shopId => {
  return request(`data/shop/${shopId}/settings.json`, {
    method: "GET"
  });
};