import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Header from "./Header";
import LeftSide from "./LeftSide";
import MainContent from "./MainContent";
import RightSide from "./RightSide";
import SnackBar from "./SnackBar";
import * as Action from "../../redux/actions";

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #e5ddd5;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

class WhatsAppShop extends Component {
  componentDidMount() {
    const { shopId } = this.props.match.params;
    this.props.getProductsJson(shopId);
    this.props.getShopSetting(shopId);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isValidShop) {
      window.location.href = window.location.origin;
    }
  }

  render() {
    const { shopId } = this.props.match.params;
    return (
      <>
        <Header />
        <MainContainer>
          <LeftSide />
          <MainContent shopId={shopId} />
          <RightSide shopId={shopId} />
        </MainContainer>
        <SnackBar />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isValidShop: state.controlProducts.isValidShop
  };
}

const mapDispatchToProps = {
  getProductsJson: Action.getProductsJson,
  getShopSetting: Action.getShopSetting
};

export default connect(mapStateToProps, mapDispatchToProps)(WhatsAppShop);
