import React, { Component } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import styled from "styled-components";

import { connect } from "react-redux";
import { closeToast } from "../../redux/actions";

const StyledContainer = styled.div`
  .toast-item {
    font-weight: bold;
  }
`;

function Alert(props) {
  return <MuiAlert elevation={1} variant="filled" {...props} />;
}

export class SnackBar extends Component {
  showAlert = () => {
    if (this.props.toastValue.status === "add") {
      return (
        <Alert onClose={this.props.closeToast} severity="info">
          <span className="toast-item">
            {this.props.toastValue.value + "  "}
          </span>
          added to cart.
        </Alert>
      );
    } else if (this.props.toastValue.status === "remove") {
      return (
        <Alert onClose={this.props.closeToast} severity="error">
          <span className="toast-item">
            {this.props.toastValue.value + "  "}
          </span>{" "}
          removed from cart.
        </Alert>
      );
    } else if (this.props.toastValue.status === "order") {
      return (
        <Alert onClose={this.props.closeToast} severity="success">
          Ordering mode set to
          <span className="toast-item">
            {"  " + this.props.toastValue.value}
          </span>{" "}
        </Alert>
      );
    }
  };

  render() {
    return (
      <StyledContainer>
        <Snackbar
          open={this.props.toastShow}
          autoHideDuration={2000}
          reason="clickaway"
          onClose={this.props.closeToast}
        >
          {this.showAlert()}
        </Snackbar>
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    toastShow: state.controlProducts.toastShow,
    toastValue: state.controlProducts.toastValue,
  };
};

const mapDispatchToProps = {
  closeToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
