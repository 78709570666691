import React, { Component } from "react";
import { MainContainer } from "./styles";
import { makeid } from "../Helper";

class WhatsAppShop extends Component {
  

  render() {
    return (
      <MainContainer>
        <a href="https://gsuite.google.com/marketplace/app/whatsapp_shop/691050264020" target="_blank" rel="noreferrer">
          <div className="integration-card">
            <img src={`assets/wa_shop.png?${makeid(10)}`} alt="" />
            <p>Create your own WhatsApp shop using Google Spreadsheet</p>
          </div>
        </a>
      </MainContainer>
    );
  }
}

export default WhatsAppShop;
