import { call, put, takeEvery } from "redux-saga/effects";
import * as Action from "../../redux/actions";
import * as Service from "./service";

export function* getProductsJson(action) {
  try {
    const response = yield call(Service.getProductsJson, action.payload);
    if (response)
      yield put({
        type: Action.GET_PRODUCTS_SUCCESS,
        payload: response,
      });
    else
      yield put({
        type: Action.GET_PRODUCTS_FAILED,
      });
  } catch (err) {
    yield put({
      type: Action.GET_PRODUCTS_FAILED,
    });
  }
}

export function* getShopSetting(action) {
  try {
    const response = yield call(Service.getShopSetting, action.payload);
    if (response)
      yield put({
        type: Action.GET_SHOP_SETTING_SUCCESS,
        payload: response,
      });
    else
      yield put({
        type: Action.GET_SHOP_SETTING_FAILED,
      });
  } catch (err) {
    yield put({
      type: Action.GET_SHOP_SETTING_FAILED,
    });
  }
}

export const whatsAppShopSagas = [
  takeEvery(Action.GET_PRODUCTS_JSON, getProductsJson),
  takeEvery(Action.GET_SHOP_SETTING, getShopSetting),
];
