import React, { Component } from "react";

export class Tab extends Component {
  render() {
    const { tab, onClick } = this.props;

    return (
      <a href={`#${tab.link}`} onClick={() => onClick(tab.link)}>
        <p className={tab.active ? "active" : ""}>{`${tab.name}(${tab.products && tab.products.length})`}</p>
      </a>
    );
  }
}

export default Tab;
