import { get } from 'lodash';
import fetch from 'node-fetch';

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
export function parseJSON(response) {
  if(!response) {
    return null;
  }
  if (response.status && (response.status === 204 || response.status === 205)) {
    return null;
  }
  return response.json();
}
export function parseBLOB(response) {
  if (response) {
    return response.blob();
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  if (response.status === 429) {
    response.json().then(res => {
      console.log(get(res, 'error.message', `Request failed! ${response.statusText}`));
    })
  }
  if (response.status === 400) {
    response.json()
      .then(res => {
        if(res.items) {
          res.items.forEach(el => {
            console.log(get(res, 'error.message', 'Request failed: ' + el.description));
        });
        }
      });
  }
  if (response.status === 403) {
    
  }
   else {
    error.response = response;
    console.log(error.response);
  }
  throw error;
}
/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to 'fetch'
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
    const defaultOptions = {};
  const newOptions = { ...defaultOptions, ...options };
  newOptions.headers = {
    'Content-Type': 'application/json',    
    'Pragma': 'no-cache',
    ...newOptions.headers
  };
  newOptions.body = JSON.stringify(newOptions.body);
  newOptions.timeout = 36000000;

  return fetch(`${window._env_.SERVER_URL}/${url}`, newOptions)
      .then(checkStatus)
      .then(parseJSON)
      .catch(err => {
        if (err.response && err.response.status === 401) {// 401 mean you aren’t authenticated,
          
        } else if (err.response && err.response.status === 403) {// 403 means know who you are, but you don’t have permission
          
        }
      })
}

export function requestCors(url, options) {
  const defaultOptions = {};
  const newOptions = { ...defaultOptions, ...options };
  newOptions.headers = {
    'Content-Type': 'application/json',
    ...newOptions.headers
  };
  newOptions.body = JSON.stringify(newOptions.body);

  return fetch(`${url}`, newOptions)
    .then(checkStatus)
    .catch(err => {
      if (err.response && err.response.status === 401) {
        localStorage.removeItem('userProfile');
        document.location.href = '/login';
      }
    })
    .then(parseJSON);
}

export function requestFile(url, options) {
  const defaultOptions = {};
  const newOptions = { ...defaultOptions, ...options };
  newOptions.headers = {
    'Content-Type': 'application/json',
    ...newOptions.headers
  };
  newOptions.body = JSON.stringify(newOptions.body);

  return fetch(`${window._env_.SERVER_URL}/${url}`, newOptions)
    .then(checkStatus)
    .catch(err => {
      
    })
    .then(parseBLOB);
}