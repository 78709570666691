import React, { Component } from "react";
import styled from "styled-components";
import ContentTableRow from "./ContentTableRow";

const StyledContainer = styled.div`
  margin: 1rem 0;
  padding-bottom: 1rem;
  padding-top: 105px;
  margin-bottom: -105px;

  p.table-title {
    background: #00bfa5;
    color: #fff;
    padding: 1rem;
  }
  .table-content {
    background: #fff;
    padding: 1rem 0;
  }
  @media screen and (max-width: 768px) {
    padding-top: 50px;
    margin-bottom: -50px;
  }
`;

export class ContentTable extends Component {
  render() {
    const { tableData, shopId, reachLast } = this.props;
    const marginBottom = reachLast ? 0 : (window.innerWidth > 768 ? -105 : -50);

    return (
      <StyledContainer id={tableData.link} style={{ marginBottom: marginBottom }}>
        <p className="table-title">{tableData.name}</p>
        <div className="table-content">
          {tableData.products &&
            tableData.products.map((product, index) => {
              return <ContentTableRow product={product} key={index} shopId={shopId} />;
            })}
        </div>
      </StyledContainer>
    );
  }
}

export default ContentTable;
