import React, { Component } from "react";
import styled from "styled-components";
import CartItemBar from "./CartItemBar";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import getSymbolFromCurrency from 'currency-symbol-map';

import { connect } from "react-redux";
import { setWhatsAppMsg } from "../../../redux/actions";

const StyledContainer = styled.div`
  background: #fff;
  .cart-title {
    display: flex;
    align-items: flex-end;
    padding: 0.7rem 1.2rem;
    border-bottom: 1px solid #ddd;
    color: #108c7e;

    .MuiSvgIcon-root {
      font-size: 1.3rem;
      margin-right: 0.5rem;
    }
    span {
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
  .cart-content {
    padding: 1rem;
  }
  .price-info {
    margin-top: 2rem;
  }

  .price-item {
    display: flex;
    justify-content: space-between;
  }
  .empty-cart {
    text-align: center;
    color: gray;
    font-weight: 500;
  }
`;

export class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartProducts: [],
      cartEmpty: true,
      subTotal: 0,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.tableDatas &&
      this.props.tableDatas !== nextProps.tableDatas
    ) {
      let _cartProducts = [].concat(
        ...nextProps.oriTableData.map((tableData) => {
          return [].concat(...tableData.products);
        })
      );
      _cartProducts = _cartProducts.filter(
        (product) => product.selectedNum > 0
      );
      let _cartEmpty = true;
      let _subTotal = 0;

      if (_cartProducts.length > 0) {
        _cartEmpty = false;
        for (let i = 0; i < _cartProducts.length; i++) {
          _subTotal += _cartProducts[i].price * _cartProducts[i].selectedNum;
        }
      } else {
        _cartEmpty = true;
      }

      let _cartInfo = {};
      _cartInfo.items = _cartProducts;
      _cartInfo.subTotal = _subTotal.toFixed(2);
      // _cartInfo.discount = (_subTotal * 0.1).toFixed(2);
      // _cartInfo.delivery = "FREE";
      _cartInfo.total = _subTotal.toFixed(2);

      this.props.setWhatsAppMsg(_cartInfo, null);

      this.setState({
        ...this.state,
        cartProducts: _cartProducts,
        cartEmpty: _cartEmpty,
        subTotal: _subTotal,
      });
    }
  }

  render() {
    const { cartEmpty, cartProducts, subTotal } = this.state;
    const { shopId, shopSetting } = this.props;
    let currencySymbol = getSymbolFromCurrency(shopSetting.Currency);
    if (!currencySymbol)
      currencySymbol = '$';
    return (
      <StyledContainer id="cart">
        <div className="cart-title">
          {cartEmpty ? <AddShoppingCartIcon /> : <CardGiftcardIcon />}
          <span>Cart</span>
        </div>
        <div className="cart-content">
          {cartProducts.map((product, index) => (
            <CartItemBar key={index} product={product} shopId={shopId} />
          ))}
          {cartEmpty ? (
            <div className="empty-cart">Opps! Cart is empty.</div>
          ) : (
            <div className="price-info">
              <div className="price-item">
                <span>Subtotal</span>
                <span>{`${currencySymbol} ${subTotal.toFixed(2)}`}</span>
              </div>
              <div className="price-item">
                <span>Total Incl Tax</span>
                <span>{`${currencySymbol} ${subTotal.toFixed(2)}`}</span>
              </div>
            </div>
          )}
        </div>
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    oriTableData: state.controlProducts.oriTableData,
    tableDatas: state.controlProducts.tableDatas,
    shopSetting: state.controlProducts.shopSetting,
  };
};

const mapDispatchToProps = {
  setWhatsAppMsg,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
