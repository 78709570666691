import React, { Component, createRef } from "react";
import styled from "styled-components";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import getSymbolFromCurrency from 'currency-symbol-map';

import { connect } from "react-redux";
import {
  setWhatsAppMsg,
  setDeliveryMode,
  setPickupMode,
} from "../../../redux/actions";

const StyledContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 7rem;
  .toggle-bar {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background: #fff;
  }
  .toggle-bar span {
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    cursor: pointer;
  }
  .toggle-bar span:hover {
    color: #108c7e;
  }
  .toggle-bar span.active {
    color: #108c7e;
    font-weight: 500;
    border-bottom: 3px solid #108c7e;
    background: #ededed;
  }
  .toggle-bar span.full {
    width: 100%;
  }

  .input-group {
    padding: 1rem;
    margin-bottom: 2rem;
    background: #fff;
  }

  .input-group-item {
    margin-bottom: 1.5rem;
  }
  .input-group-item p {
    font-size: 0.8rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 0.5rem;
  }
  .input-group-item input {
    outline: none;
    border: none;
    width: 100%;
    border-bottom: 1px solid #aaa;
    padding: 0.4rem 0;
  }
  .input-group-item input:focus {
    border-bottom: 1px solid #000;
  }

  .additional-bar {
    margin-bottom: 2rem;
  }
  .additional-bar textarea {
    width: 100%;
    height: 5rem;
    padding: 0.5rem;
    border-color: #ababab;
    border-radius: 5px;
    margin-top: 0.5rem;
  }
  .additional-bar textarea:focus {
    outline: none;
    box-shadow: 0px 0px 5px 1px #00bfa5;
  }
  .additional-bar p {
    color: #555;
  }
  .order-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #108c7e;
    padding: 0.5rem;
    border-radius: 5px;
    margin-top: 1.5rem;
    cursor: pointer;
    text-decoration: none;
  }
  .order-btn:hover {
    color: #000;
    box-shadow: 0px 3px 5px 0px #888;
  }
  .order-btn .MuiSvgIcon-root {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
`;

export class SettingNav extends Component {
  constructor() {
    super();
    this.state = {
      orderMsg: "",
      error: "",
    };
    this.nameRef = createRef();
    this.contactRef = createRef();
    this.add1Ref = createRef();
    this.add2Ref = createRef();
    this.noteRef = createRef();
  }

  handleOrder = () => {
    let _userInfo = {};
    let _orderMsg = "";
    let _cartItems = "";
    const { whatsAppMsg, orderMode, shopSetting } = this.props;
    const currency = shopSetting.Currency ? shopSetting.Currency : "USD";
    let currencySymbol = getSymbolFromCurrency(currency);
    if (!currencySymbol)
      currencySymbol = '$';
    const shopName = shopSetting.ShopName
      ? shopSetting.ShopName
      : "Whatsapp Shop";

    if (whatsAppMsg.cartInfo.items.length === 0) {
      this.setState({
        ...this.state,
        error: "Cart is empty"
      });
      return;
    }
    _userInfo.name = this.nameRef.current.value;
    if (!_userInfo.name || _userInfo.name === "") {
      this.setState({
        ...this.state,
        error: "Please input your name"
      });
      return;
    }
    _userInfo.contact = this.contactRef.current.value;
    if (!_userInfo.contact || _userInfo.contact === "") {
      this.setState({
        ...this.state,
        error: "Please input your contact number"
      });
      return;
    }
    _userInfo.address_1 = this.add1Ref.current && this.add1Ref.current.value;
    if (orderMode === "delivery" && (!_userInfo.address_1 || _userInfo.address_1 === "")) {
      this.setState({
        ...this.state,
        error: "Please input Address line 1"
      });
      return;
    }
    _userInfo.address_2 = this.add2Ref.current && this.add2Ref.current.value;
    if (orderMode === "delivery" && (!_userInfo.address_2 || _userInfo.address_2 === "")) {
      this.setState({
        ...this.state,
        error: "Please input Address line 2"
      });
      return;
    }
    _userInfo.note = this.noteRef.current.value;
    this.props.setWhatsAppMsg(null, _userInfo);

    for (let i = 0; i < whatsAppMsg.cartInfo.items.length; i++) {
      _cartItems += `* ${whatsAppMsg.cartInfo.items[i].selectedNum} x ${whatsAppMsg.cartInfo.items[i].name} ${currencySymbol} ${whatsAppMsg.cartInfo.items[i].price} \n    `;
    }

    _orderMsg = `
    Hi, I'd like to place an order 👇
    🧑‍🚒
    *${orderMode === "delivery" ? "Delivery" : "Pickup"} Order*
    From ------
    ${window.location.href}

    🎁 Order Items ------
    ${_cartItems}
    SubTotal : ${currencySymbol} ${whatsAppMsg.cartInfo.subTotal}
    Total Incl Tax : ${currencySymbol} ${whatsAppMsg.cartInfo.total}

    🤵 Your Information ------
    Name: ${_userInfo.name}
    Contact number: ${_userInfo.contact}
    ${orderMode === "delivery" ? `Address 1: ${_userInfo.address_1}` : ""}
    ${orderMode === "delivery" ? `Address 2: ${_userInfo.address_2}` : ""}
    Special instructions: ${_userInfo.note}
    -----------------------------------------------
    *${shopName}* will confirm your order upon receiving the message.🎉
    Thank you.🙏🏻
    `;
    this.setState({
      ...this.state,
      error: "",
      orderMsg: _orderMsg,
    });
    window.open(`https://api.whatsapp.com/send?phone=${shopSetting.WANumber}&text=${encodeURIComponent(_orderMsg)}`, "_blank");
  };

  render() {
    const { shopSetting } = this.props;
    const { error } = this.state;
    if (shopSetting.Pickup && !shopSetting.Delivery) this.props.setPickupMode();
    return (
      <StyledContainer>
        <div className="toggle-bar">
          {shopSetting.Delivery && (
            <span
              onClick={this.props.setDeliveryMode}
              className={
                this.props.orderMode === "delivery"
                  ? `active ${shopSetting.Pickup ? "" : "full"}`
                  : ""
              }
            >
              Delivery
            </span>
          )}
          {shopSetting.Pickup && (
            <span
              onClick={this.props.setPickupMode}
              className={
                this.props.orderMode === "pickup"
                  ? `active  ${shopSetting.Delivery ? "" : "full"}`
                  : ""
              }
            >
              Pickup
            </span>
          )}
        </div>
        <div className="input-group">
          <div className="input-group-item">
            <p>Name</p>
            <input
              ref={this.nameRef}
              type="text"
              placeholder="Your name"
              required
            />
          </div>
          <div className="input-group-item">
            <p>Contact number</p>
            <input
              ref={this.contactRef}
              type="text"
              placeholder="Enter contact number"
              required
            />
          </div>
          {this.props.orderMode === "delivery" ? (
            <>
              <div className="input-group-item">
                <p>Address line 1</p>
                <input
                  ref={this.add1Ref}
                  type="text"
                  placeholder="Unit / Building name / Locality"
                />
              </div>
              <div className="input-group-item">
                <p>Address line 2</p>
                <input
                  ref={this.add2Ref}
                  type="text"
                  placeholder="Landmark / City / Postal code"
                />
              </div>
            </>
          ) : null}
          {error && <div style={{ color: "red" }}>{error}</div>}
        </div>
        <div className="additional-bar">
          <span>Special instructions(Optional)</span>
          <textarea ref={this.noteRef} />
          <p>We'll never share your details with anyone else.</p>
          <div
            className="order-btn"
            onClick={this.handleOrder}
          >
            <WhatsAppIcon />
            <span>Order on Whatsapp</span>
          </div>
        </div>
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    whatsAppMsg: state.controlProducts.whatsAppMsg,
    orderMode: state.controlProducts.orderMode,
    shopSetting: state.controlProducts.shopSetting,
  };
};

const mapDispatchToProps = {
  setWhatsAppMsg,
  setDeliveryMode,
  setPickupMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingNav);
