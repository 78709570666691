import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import getSymbolFromCurrency from 'currency-symbol-map';
import { increaseProductNum, decreaseProductNum } from "../../../redux/actions";
import { getProductUrl } from "../../Helper";

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  .cart-info {
    display: flex;
    align-items: center;
  }
  .cart-info span {
    font-size: 0.9rem;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 1rem;
  }

  .cart-setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
  }

  .cart-setting button {
    color: #108c7e;
    outline: none;
    border: none;
    background: transparent;
    font-size: 1.3rem;
    cursor: pointer;
  }
  .cart-setting p {
    font-size: 0.9rem;
  }
`;

export class CartItemBar extends Component {
  
  render() {
    const { product, increaseProductNum, decreaseProductNum, shopId, shopSetting } = this.props;
    let currencySymbol = getSymbolFromCurrency(shopSetting.Currency);
    if (!currencySymbol)
      currencySymbol = '$';
    return (
      <StyledContainer>
        <div className="cart-info">
          <img src={getProductUrl(product.image, shopId)} alt="category" />
          <span>
            {`${product.name} x ${product.selectedNum}`}
          </span>
        </div>
        <div className="cart-setting">
          <button onClick={() => decreaseProductNum(product.id)}>-</button>
          <p>{`${currencySymbol}${(product.price * product.selectedNum).toFixed(2)}`}</p>
          <button onClick={() => increaseProductNum(product.id)}>+</button>
        </div>
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tableDatas: state.controlProducts.tableDatas,
    shopSetting: state.controlProducts.shopSetting,
  };
};
const mapDispatchToProps = {
  increaseProductNum,
  decreaseProductNum,
};
export default connect(mapStateToProps, mapDispatchToProps)(CartItemBar);
