import { combineReducers } from "redux";
import * as Action from "./actions";
// import _tableDatas from "../data.json";

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const initialState = {
  isValidShop: true,
  oriTableData: [],
  tableDatas: [],
  totalCartNum: 0,
  toastShow: false,
  orderMode: "delivery",
  toastValue: {
    status: "",
    value: "",
  },
  whatsAppMsg: {
    cartInfo: {
      items: [],
      subTotal: 0,
      discount: 0,
      delivery: "",
      total: 0,
    },
    userInfo: {
      name: "",
      contact: "",
      address_1: "",
      address_2: "",
      note: "",
    },
  },
  shopSetting: {},
};

function controlProducts(state = initialState, action) {
  switch (action.type) {
    case Action.INCREASE_PRODUCT_NUM: {
      let newTableDatas = JSON.parse(JSON.stringify(state.tableDatas));
      let product_name = "";
      newTableDatas = newTableDatas.map((tableData) => {
        tableData.products.map((product) => {
          if (product.id === action.payload) {
            product.selectedNum++;
            product_name = product.name;
          }
          return product;
        });
        return tableData;
      });
      let totalNum = state.totalCartNum;
      totalNum++;
      let _toastValue = {};
      _toastValue.status = "add";
      _toastValue.value = product_name;

      let oriTableData = JSON.parse(JSON.stringify(state.oriTableData));
      oriTableData = oriTableData.map((tableData) => {
        tableData.products.map((product) => {
          if (product.id === action.payload) {
            product.selectedNum++;
          }
          return product;
        });
        return tableData;
      });

      return {
        ...state,
        oriTableData: oriTableData,
        tableDatas: newTableDatas,
        totalCartNum: totalNum,
        toastShow: true,
        toastValue: _toastValue,
      };
    }
    case Action.DECREASE_PRODUCT_NUM: {
      let newTableDatas = JSON.parse(JSON.stringify(state.tableDatas));
      let product_name = "";
      newTableDatas = newTableDatas.map((tableData) => {
        tableData.products.map((product) => {
          if (product.id === action.payload) {
            product.selectedNum--;
            product_name = product.name;
          }
          return product;
        });
        return tableData;
      });
      let totalNum = state.totalCartNum;
      totalNum--;
      let _toastValue = {};
      _toastValue.status = "remove";
      _toastValue.value = product_name;

      let oriTableData = JSON.parse(JSON.stringify(state.oriTableData));
      oriTableData = oriTableData.map((tableData) => {
        tableData.products.map((product) => {
          if (product.id === action.payload) {
            product.selectedNum--;
          }
          return product;
        });
        return tableData;
      });

      return {
        ...state,
        oriTableData: oriTableData,
        tableDatas: newTableDatas,
        totalCartNum: totalNum,
        toastShow: true,
        toastValue: _toastValue,
      };
    }
    case Action.SEARCH_PRODUCTS: {
      let newDatas = [];
      state.oriTableData.map((searchedData) => {
        let newData = JSON.parse(JSON.stringify(searchedData));
        let searchedProducts = newData.products.filter(
          (product) =>
            product.name.toLowerCase().includes(action.payload.toLowerCase()) ||
            product.description
              .toLowerCase()
              .includes(action.payload.toLowerCase())
        );
        if (searchedProducts && searchedProducts.length > 0) {
          newData.products = searchedProducts;
          newDatas.push(newData);
        }
        return null;
      });
      return {
        ...state,
        tableDatas: newDatas,
      };
    }
    case Action.SET_WHATSAPP_MESSAGE: {
      let newMsg = {};
      if (action.cartInfo !== null) {
        newMsg.cartInfo = action.cartInfo;
      } else {
        newMsg.cartInfo = state.whatsAppMsg.cartInfo;
      }
      if (action.userInfo !== null) {
        newMsg.userInfo = action.userInfo;
      } else {
        newMsg.userInfo = state.whatsAppMsg.userInfo;
      }
      return {
        ...state,
        whatsAppMsg: newMsg,
      };
    }
    case Action.SET_DELIVERY_MODE: {
      let _toastValue = {};
      _toastValue.status = "order";
      _toastValue.value = "Delivery";
      return {
        ...state,
        orderMode: "delivery",
        toastShow: true,
        toastValue: _toastValue,
      };
    }
    case Action.SET_PICKUP_MODE: {
      let _toastValue = {};
      _toastValue.status = "order";
      _toastValue.value = "Pick up";
      return {
        ...state,
        orderMode: "pickup",
        toastShow: true,
        toastValue: _toastValue,
      };
    }
    case Action.CLOSE_TOAST:
      if (action.reason === "clickaway") {
        return state;
      }
      return {
        ...state,
        toastShow: false,
      };
    case Action.GET_PRODUCTS_SUCCESS: {
      let groups = groupBy(action.payload, "ProductCategory");
      let newTableData = [];
      let index = 0;
      for (let key in groups) {
        index++;
        let category = {};
        category.link = key;
        category.name = key;
        category.active = index === 1;
        let products = [];
        groups[key].map((product, index) => {
          let newProduct = {};
          newProduct.id = key + index;
          newProduct.name = product.ProductName;
          newProduct.description = product.ProductDescription;
          newProduct.image = product.Image;
          newProduct.price = product.Price;
          newProduct.selectedNum = 0;
          if (
            product.Active &&
            product.Active.toLowerCase().includes("y") &&
            !products.find(
              (x) =>
                x.name === product.ProductName &&
                x.description === product.ProductDescription &&
                x.price === product.Price
            )
          )
            products.push(newProduct);
          return product;
        });
        category.products = products;
        if (products.length > 0) newTableData.push(category);
      }
      return {
        ...state,
        oriTableData: newTableData,
        tableDatas: newTableData,
        isValidShop: true,
      };
    }
    case Action.GET_PRODUCTS_FAILED: {
      return {
        ...state,
        isValidShop: false,
      };
    }
    case Action.GET_SHOP_SETTING_SUCCESS: {
      return {
        ...state,
        shopSetting: action.payload,
      };
    }
    default:
      return state;
  }
}

export const rootState = combineReducers({
  controlProducts,
});
