import React, { Component } from "react";
import styled from "styled-components";
import Cart from "./Cart";
import CartButton from "./CartButton";
import SettingNav from "./SettingNav";

const StyledContainer = styled.div`
  width: 25%;
  min-width: 200px;
  height: 100%;
  padding: 1rem;
  margin-top: 1rem;

  @media screen and (max-width: 992px) {
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export class RightSide extends Component {
  render() {
    const { shopId } = this.props;
    return (
      <StyledContainer>
        <Cart shopId={shopId} />
        <SettingNav />
        <CartButton />
      </StyledContainer>
    );
  }
}

export default RightSide;
