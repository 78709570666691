import React, { Component } from "react";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import ContentTable from "./ContentTable";

import { connect } from "react-redux";
import { searchProducts } from "../../../redux/actions";

const StyledContainer = styled.div`
  width: 57%;
  margin-left: 18%;
  padding: 1rem;

  @media screen and (max-width: 992px) {
    margin-left: 0;
    width: 65%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const StyledSearch = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -105px;

  input {
    outline: none;
    border: 1px solid #aaa;
    border-radius: 0px 5px 5px 0px;
    padding: 0.6rem;
    width: 100%;
  }
  input:hover {
    border: 1px solid #1d9183;
  }
  input:focus {
    border: 1px solid #1d9183;
  }
  .search-icon {
    display: flex;
    background: #00bfa5;
    color: #fff;
    padding: 0.375rem 0.6rem;
    border-radius: 5px 0px 0px 5px;
    margin: 1rem 0;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: -50px;
  }
`;

export class MainContent extends Component {
  render() {
    const { shopId, tableDatas } = this.props;
    return (
      <StyledContainer>
        <StyledSearch>
          <div className="search-icon">
            <SearchIcon />
          </div>
          <input
            onChange={(e) => this.props.searchProducts(e.target.value)}
            type="text"
            placeholder="Search"
          />
        </StyledSearch>
        {tableDatas &&
          tableDatas.map((tableData, index) => {
            return <ContentTable tableData={tableData} shopId={shopId} key={index} reachLast={index === tableDatas.length - 1} />;
          })}
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tableDatas: state.controlProducts.tableDatas,
  };
};
const mapDispatchToProps = {
  searchProducts,
};
export default connect(mapStateToProps, mapDispatchToProps)(MainContent);
