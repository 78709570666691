export const INCREASE_PRODUCT_NUM = "INCREASE_PRODUCT_NUM";
export const DECREASE_PRODUCT_NUM = "DECREASE_PRODUCT_NUM";
export const SEARCH_PRODUCTS = "SEARCH_PRODUCTS";
export const SET_WHATSAPP_MESSAGE = "SET_WHATSAPP_MESSAGE";
export const CLOSE_TOAST = "CLOSE_TOAST";
export const SET_DELIVERY_MODE = "SET_DELIVERY_MODE";
export const SET_PICKUP_MODE = "SET_PICKUP_MODE";
export const GET_PRODUCTS_JSON = "GET_PRODUCTS_JSON";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED";
export const GET_SHOP_SETTING = "GET_SHOP_SETTING";
export const GET_SHOP_SETTING_SUCCESS = "GET_SHOP_SETTING_SUCCESS";
export const GET_SHOP_SETTING_FAILED = "GET_SHOP_SETTING_FAILED";

export const increaseProductNum = (payload) => ({
  type: INCREASE_PRODUCT_NUM,
  payload,
});
export const decreaseProductNum = (payload) => ({
  type: DECREASE_PRODUCT_NUM,
  payload,
});
export const searchProducts = (payload) => ({
  type: SEARCH_PRODUCTS,
  payload,
});
export const setWhatsAppMsg = (cartInfo, userInfo) => ({
  type: SET_WHATSAPP_MESSAGE,
  cartInfo,
  userInfo,
});
export const closeToast = (event, reason) => ({
  type: CLOSE_TOAST,
  event,
  reason,
});
export const setDeliveryMode = () => ({
  type: SET_DELIVERY_MODE,
});
export const setPickupMode = () => ({
  type: SET_PICKUP_MODE,
});
export const getProductsJson = (shopId) => ({
  type: GET_PRODUCTS_JSON,
  payload: shopId
});
export const getShopSetting = (shopId) => ({
  type: GET_SHOP_SETTING,
  payload: shopId
});