import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { makeid } from "../Helper";

const StyledContainer = styled.div`
  height: 105px;
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 5px gray;
  background: #ededed;
  align-items: center;
  .MuiGrid-spacing-xs-3 {
    width: 100% !important;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    height: auto;
    box-shadow: 0 0 1px gray;
  }
`;

const Logo = styled.div`
  padding: 1rem 2rem;
  margin: auto;
  img {
    width: 70px;
    height: 70px;
  }

  @media screen and (max-width: 768px) {
    padding: 0.5rem;
    img {
      width: 50px;
      height: 50px;
    }
  }
`;
const HeaderTitle = styled.div`
  display: flex;
  h1 {
    font-size: 2.3rem;
    color: #454545;
  }
  h2 {
    font-size: 1rem;
    color: #454545;
    max-width: 70%;
    padding-bottom: 1rem;
  }
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
    h1 {
      font-size: 1.8rem;
      color: #454545;
    }
    h2 {
      font-size: 0.7rem;
      color: #454545;
      max-width: 100%;
      padding-bottom: 0;
    }
  }
`;
const ExtraInfo = styled.div`
  margin: auto;
  div {
    display: flex;
    justify-content: right;
    color: #454545;
  }
  @media screen and (max-width: 768px) {
    margin: 0 0 15px 20px;
  }
`;

class Header extends Component {

  render() {
    const { shopSetting } = this.props;
    const browserTitle = shopSetting.ShopName
      ? `${shopSetting.ShopName} - powered by wati.io`
      : "Whatsapp Shop - powered by wati.io";
    const logoUrl = shopSetting.LogoUrl
      ? `${window._env_.SERVER_URL}/data/shop/${shopSetting.ShopId}/${shopSetting.LogoUrl}`
      : "assets/logo.png";
    return (
      <StyledContainer>
        <Helmet>
          <title>{browserTitle}</title>
        </Helmet>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
            <HeaderTitle>
              <Logo>
                <img src={`${logoUrl}?${makeid(10)}`} alt="logo" />
              </Logo>
              <div style={{ width: "100%", margin: "auto 0" }}>
                <h1>{shopSetting.ShopName}</h1>
                <h2>{shopSetting.ShopDesc}</h2>
              </div>
            </HeaderTitle>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            style={{ display: "flex" }}
          >
            <ExtraInfo>
              <div>
                <AttachMoneyIcon style={{ color: "#b5b533" }} />:{" "}
                {shopSetting.Currency}
              </div>
              <div>
                <WhatsAppIcon style={{ color: "green" }} />:{" "}
                {shopSetting.WANumber}
              </div>
            </ExtraInfo>
          </Grid>
        </Grid>
      </StyledContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    shopSetting: state.controlProducts.shopSetting,
  };
}

export default connect(mapStateToProps)(Header);
