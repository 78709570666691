import React, { Component } from "react";
import styled from "styled-components";
import Tab from "./Tab";
import { connect } from "react-redux";

const StyledContainer = styled.div`
  width: 18%;
  overflow: auto;
  padding: 1rem;
  position: fixed;
  height: calc(100vh - 105px);
  background: #e5ddd5;
  margin-top: 1rem;
  a {
    text-decoration: none;
  }
  p {
    padding: 0.8rem 1rem;
    white-space: nowrap;
    cursor: pointer;
    color: #000;
  }
  p:hover {
    color: #1d9183;
  }
  p.active {
    background: #ebebeb;
    border-left: 3px solid #1d9183;
    color: #1d9183;
  }

  @media screen and (max-width: 992px) {
    height: auto;
    display: flex;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    background: #e5ddd5;
    padding: 0 1rem;
    margin-top: 0;
    box-shadow: 0px 3px 7px 0px #cacaca;

    &:some-pseudo-class {
      background: #ff0;
    }

    p.active {
      background: #eee;
      border: none;
      border-bottom: 2px solid #1d9183;
    }
  }
`;

export class LeftSide extends Component {
  constructor() {
    super();
    this.state = {
      tabs: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tabItems && this.props.tabItems !== nextProps.tabItems) {
      this.setState({
        ...this.state,
        tabs: nextProps.tabItems,
      });
    }
  }

  handleTabClick = (value) => {
    let newTabs = this.state.tabs.map((tab) => {
      if (tab.link === value) {
        tab.active = true;
      } else {
        tab.active = false;
      }
      return tab;
    });

    this.setState(newTabs);
  };

  render() {
    return (
      <StyledContainer>
        {this.state.tabs.map((tab, index) => {
          return <Tab tab={tab} key={index} onClick={this.handleTabClick} />;
        })}
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tabItems: state.controlProducts.tableDatas,
  };
};

export default connect(mapStateToProps)(LeftSide);
