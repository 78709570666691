import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import createSagaMiddleware from 'redux-saga';
import { Provider } from "react-redux";
import { createStore, applyMiddleware  } from "redux";
import { rootState } from "./redux/reducer";
import rootSaga from "./saga";
import WhatsAppShop from "./components/WhatsAppShop";
import Home from "./components/Home";

function App() {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(rootState, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(rootSaga);
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/:shopId" component={WhatsAppShop} />
          <Redirect
            from="*"
            to={{ pathname: "/", state: { from: window.location.pathname } }}
          />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
