import React, { Component } from "react";
import styled from "styled-components";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";

import { connect } from "react-redux";

const StyledContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 2.5rem;
  right: 1.2rem;

  .btn-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #108c7e;
    box-shadow: 0px 0px 5px 0px #555;
    cursor: pointer;

    .MuiSvgIcon-root {
      font-size: 2rem;
      font-weight: 300;
    }
    .badge {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: -5px;
      top: -8px;
      color: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #00bfa5;
      border: 1px solid #fff;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .btn-wrapper:hover {
    box-shadow: 0px 0px 7px 0px #333;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export class CartButton extends Component {
  render() {
    return (
      <StyledContainer>
        <a href="#cart" className="btn-wrapper">
          <ShoppingCartOutlinedIcon />
          {this.props.totalCartNum > 0 && (
            <div className="badge">
              <span>{this.props.totalCartNum}</span>
            </div>
          )}
        </a>
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    totalCartNum: state.controlProducts.totalCartNum,
  };
};
export default connect(mapStateToProps)(CartButton);
