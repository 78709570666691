export const getProductUrl = (content, shopId) => {
  if (!content) return "";
  if (content.startsWith("http:") || content.startsWith("https:"))
    return content;
  return `${window._env_.SERVER_URL}/data/shop/${shopId}/${content}?${makeid(10)}`
};

export const makeid = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}