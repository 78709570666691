import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import ClickAwayListener from "react-click-away-listener";
import { increaseProductNum, decreaseProductNum } from "../../../redux/actions";
import { getProductUrl } from "../../Helper";

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
  background: #fff;
  &:hover {
    background: #ededed;
  }
  p {
    background: transparent;
    padding: 0;
    color: #000;
  }
`;

const CategoryInfo = styled.div`
  display: flex;

  align-items: center;
  img {
    height: 50px;
    margin-right: 1rem;
  }
  .category-desc {
    font-size: 0.8rem;
    color: #555;
  }
  .catetory-title {
    display: flex;
    margin-bottom: 0.5rem;
  }
  .catetory-badge {
    display: flex;
    background: #dcf8c6;
    padding: 0 0.5rem;
    border-radius: 10px;
    margin-left: 0.3rem;
  }
`;

const CategoryOption = styled.div`
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: space-between;
  margin-left: 3rem;
  button {
    outline: none;
    border: none;
    background: transparent;
    font-size: 1.5rem;
    color: #1d9183;
    cursor: pointer;
  }
  button.disabled {
    color: #88c6bf;
    cursor: not-allowed;
  }
`;

const ImageEnlarger = styled.div`
  position: absolute;
  padding: 2rem;
  width: 100%;
  height: 70%;
  display: flex;
  top: 150px;
  left: 0;
  .image-container {
    margin: auto;
  }
  img {
    height: 600px;
    z-index: 100000;
    box-shadow: 0 0 3px 3px gray;
  }
  @media screen and (max-width: 768px) {
    padding: 0.5rem;
    img {
      height: auto;
      width: 100%;
    }
  }
`;

export class ContentTableRow extends Component {
  state = {
    openImageEnlarger: false,
  };

  // componentDidMount() {
  //   window.addEventListener('scroll', this.handleScrollChange);
  // }

  // handleScrollChange = () => {
  //   this.setState({
  //     ...this.state,
  //     openImageEnlarger: false,
  //   });
  // }

  handleOpenImageEnlarger = () => {
    this.setState({
      ...this.state,
      openImageEnlarger: true,
    });
  };

  handleCloseImageEnlarger = () => {
    this.setState({
      ...this.state,
      openImageEnlarger: false,
    });
  };

  render() {
    const {
      product,
      increaseProductNum,
      decreaseProductNum,
      shopId,
      shopSetting,
    } = this.props;
    const { openImageEnlarger } = this.state;
    let currencySymbol = getSymbolFromCurrency(shopSetting.Currency);
    if (!currencySymbol) currencySymbol = "$";
    return (
      <StyledContainer>
        <CategoryInfo>
          <img
            src={getProductUrl(product.image, shopId)}
            alt="category"
            onClick={this.handleOpenImageEnlarger}
          />
          <div className="category-info-wrapper">
            <div className="catetory-title">
              <span>{product.name}</span>
              {product.selectedNum > 0 && (
                <span className="catetory-badge">{product.selectedNum}</span>
              )}
            </div>
            <p className="category-desc">{product.description}</p>
          </div>
        </CategoryInfo>
        {openImageEnlarger && (
          <ImageEnlarger style={{ top: window.scrollY + 150 }}>
            <ClickAwayListener className="image-container" onClickAway={this.handleCloseImageEnlarger}>
              <img
                src={getProductUrl(product.image, shopId)}
                alt="category"
                onClick={this.handleCloseImageEnlarger}
              />
            </ClickAwayListener>
          </ImageEnlarger>
        )}
        <CategoryOption>
          {product.selectedNum <= 0 ? (
            <button className="disabled" disabled>
              -
            </button>
          ) : (
            <button onClick={() => decreaseProductNum(product.id)}>-</button>
          )}
          <p>
            {currencySymbol} {product.price.toFixed(2)}
          </p>
          <button onClick={() => increaseProductNum(product.id)}>+</button>
        </CategoryOption>
      </StyledContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    shopSetting: state.controlProducts.shopSetting,
  };
}

const mapDispatchToProps = {
  increaseProductNum,
  decreaseProductNum,
};
export default connect(mapStateToProps, mapDispatchToProps)(ContentTableRow);
